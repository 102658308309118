<template>
	<div>
		<Header :childIndex='3' :val='2'></Header>
		<div class="banner2">
			<img v-if="bannerList.length > 0" :src="$ImgUrl + bannerList[0].PicPath" :onerror="defaultImg" />
			<img v-if="bannerList.length == 0" src="../../images/研究成果库banner.jpg" />
			<div class="banner2_c">
				<div class="banner2_c1">
					<div class="banner2_cl">
						<el-select v-model="searchType">
							<el-option  v-for="item in searchAcheievList" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
						<input type="text" v-model="keyWord" />
						<a href="javascript:void(0)" @click="toSearchList()">检索</a>
					</div>
					<a href="javascript:void(0)" @click="toSeniorSearch()">高级搜索</a>
				</div>
				<div class="banner2_c2">
					<i>热门搜索:</i>
					<a href="javascript:void(0)" @click="chooseSearch(value.SearchKey)" v-for="(value,index) in SearchWordsList" :key="index">{{value.SearchKey}}</a>
				</div>
			</div>
		</div>
		<div class="yjcgsy_box">
			<div class="yjcgsy_box1">
				<div class="yjcgsy_box1c">
					<div class="content_title">
						<i></i>
						<img src="../../images/ts_ico.png">
						<a href="javascript:void(0)" @click="toSearchAchievtList(0)">更多 >></a>
					</div>
					<div class="yjcgsy_box1cbox">
						<ul>
							<li v-for="(value,index) in BookList" :key="index">
								<div class="yjcgsy_left">
									<a href="javascript:void(0)" @click="toBookDetail(value.id)">
										<img :src="$ImgUrl + value.cover_path" :onerror="$parent.defaultImg1" />
									</a>
								</div>
								<div class="yjcgsy_right">
									<a href="javascript:void(0)" @click="toBookDetail(value.id)">
										<h2>{{value.res_name}}</h2>
									</a>
									<h3>{{value.res_author}}</h3>
									<p v-html="value.Itroduce"></p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="yjcgsy_box2 h310">
				<div class="yjcgsy_box2_c">
					<div class="content_title">
						<i></i>
						<img src="../../images/qk_ico2.png">
					</div>
					<div class="yjcgsy_box1cbox yjcgsy_box1cboxqk">
						<ul>
							<li v-for="(value,index) in JounelList" :key="index">
								<div class="yjcgsy_left">
									<a href="javascript:void(0)" @click="toGeneralJournaDetail(value)">
										<img :src="$ImgUrl + value.CoverPath" :onerror="$parent.defaultImg1" />
									</a>
								</div>
								<div class="yjcgsy_right">
									<a href="javascript:void(0)" @click="toGeneralJournaDetail(value)">
										<h2>{{value.JournalName}}</h2>
									</a>
									<h3>ISSN:<i>{{value.ISSN}}</i></h3>
									<h3>CN:<i>{{value.CN}}</i></h3>
									<p>{{value.Itroduce | GetSubstring(30)}}</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="yjcgsy_box3">
				<div class="yjcgsy_box3_c">
					<div class="yjcgsy_box3_cl">
						<div class="content_title">
							<i></i>
							<img src="../../images/lw_ico.png">
							<a href="javascript:void(0)" @click="toSearchAchievtList(1)">更多 >></a>
						</div>
						<div class="lw_boxlist">
							<ul>
								<li v-for="(value,index) in ArticalList" :key="index">
									<a href="javascript:void(0)" @click="toEntryDetail(value.id)">{{value.entry_name}}</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="yjcgsy_box3_cr">
						<div class="content_title">
							<i></i>
							<img src="../../images/qt_ico2.png">
							<a href="javascript:void(0)" @click="toSearchAchievtList(2)">更多 >></a>
						</div>
						<div class="qt_boxlist">
							<ul>
								<li v-for="(value,index) in OtherList" :key="index">
									<a href="javascript:void(0)" @click="toEntryDetail(value.id)">
										<h2>{{value.entry_name}}</h2>
									</a>
									<p>{{value.itroduce}}</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script src="./SearchAchievtIndex.js">
</script>

<style scoped="scoped">
	.yjcgsy_right p{ height: 108px; overflow: hidden;}
</style>
